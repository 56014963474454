import { compare } from '../utils';
import {
  ILogDependencyRuleAction,
  LogDependencyRuleAction
} from './log-dependency-rule-action.model';
import {
  ILogDependencyRuleCondition,
  LogDependencyRuleCondition
} from './log-dependency-rule-condition';

export interface ILogDependencyRule {
  conditions: ILogDependencyRuleCondition[];
  action: ILogDependencyRuleAction;
}

export class LogDependencyRule implements ILogDependencyRule {
  conditions: LogDependencyRuleCondition[];
  action: LogDependencyRuleAction;

  constructor(rule: ILogDependencyRule) {
    if (rule?.conditions?.length > 0) {
      this.conditions = rule.conditions.map(
        (condition) => new LogDependencyRuleCondition(condition)
      );
    } else {
      this.conditions = [];
    }

    this.action = new LogDependencyRuleAction(rule?.action);
  }

  validate(log: any): boolean {
    return this.conditions.every((condition) => {
      if (!condition.field) {
        throw new Error('property "field" must have a truthy value');
      }

      if (!condition.operator) {
        throw new Error('property "operator" must have a truthy value');
      }

      if (condition.value === null || condition.value === undefined) {
        throw new Error('property "value" must have a truthy value');
      }

      const sourceValue = log[condition.field];
      const targetValue = condition.value;
      const operator = condition.operator;
      const validOperators = ['<', '<=', '=', '!=', '>=', '>'];
      const validStringOperators = ['=', '!='];

      switch (typeof sourceValue) {
        case 'string':
          if (!validStringOperators.includes(operator)) {
            throw new Error(
              'Log field value is of type string which can only be used with the following operators: =, !='
            );
          }
          break;

        case 'number':
          if (!validOperators.includes(operator)) {
            throw new Error(
              'Log field value is of type string which can only be used with the following operators: <, <=, =, !=, >=, >'
            );
          }
          break;

        case 'boolean':
          if (validStringOperators.includes(operator)) {
            throw new Error(
              'Log field value is of type boolean which can only be used with the following operators: =, !='
            );
          }
          break;

        default:
          throw new Error(
            `Log field value is of type ${typeof sourceValue} which is not supported`
          );
      }

      return compare(sourceValue, operator, targetValue);
    });
  }
}

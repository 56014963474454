import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const MedicationReceivingPageActions = createActionGroup({
  source: 'Medication Receiving Page',
  events: {
    'Load Pharmacy Api Messages': (params: Params) => ({ params }),
    'Get Medical Professionals': (params: Params) => ({ params }),
    'Get Pharmacies': (params: Params) => ({ params })
  }
});

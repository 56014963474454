import { getRecordChanges } from '$shared';
import { Component, computed, input } from '@angular/core';
import { AlcCommonModule } from '../../alc-common.module';
import { AlcInfoBlockComponent } from '../info-block/info-block.component';

type Diff = {
  field: string;
  fieldLabel: string;
  tooltip: string | undefined;
  original: any;
  new: any;
};

/**
 * This component shows the differences between two objects. This is useful for
 * showing a summary of changes that are taking place in a form.
 */
@Component({
  selector: 'alc-diff',
  standalone: true,
  imports: [AlcCommonModule, AlcInfoBlockComponent],
  template: `
    <div class="flex flex-col gap-6">
      @for (diff of diffs(); track diff.field) {
        <alc-info-block
          class="basis-full"
          [label]="diff.fieldLabel"
          [tooltip]="diff.tooltip"
        >
          <div class="flex items-center gap-6">
            <div>{{ diff.original }}</div>

            <div class="w-8 flex-none justify-center">
              <ion-icon
                class="shrink-0"
                src="assets/icon/material/arrow_forward.svg"
              />
            </div>

            <div>{{ diff.new }}</div>
          </div>
        </alc-info-block>
      }
    </div>
  `
})
export class AlcDiffComponent {
  original = input<any>();
  new = input<any>();
  /**
   * An array of tuples that contain the field key, field label, and optional
   * tooltip for each field that should be displayed in the diff.
   *
   * @memberof AlcDiffComponent
   */
  fieldLabels = input<[string, string, string?][]>();

  diffs = computed((): Diff[] => {
    const diff = [];

    let changes;

    if (this.original() && this.new()) {
      changes = getRecordChanges(this.original(), this.new());
    } else {
      changes = this.new();
    }

    for (const [key, fieldLabel, tooltip] of this.fieldLabels()) {
      if (changes[key] === undefined) {
        continue;
      }

      diff.push({
        field: key,
        fieldLabel,
        tooltip,
        original: this.original()?.[key] || 'N/A',
        new: changes[key]
      });
    }

    return diff;
  });
}

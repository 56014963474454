<ion-header>
  <ion-toolbar color="residents-primary">
    <ion-title>{{
      medicationInventoryItem
        ? 'Update Inventory Item'
        : 'Add New Inventory Item'
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-item lines="full">
  <alc-avatar
    type="resident"
    class="mr-4"
    size="40"
    [gender]="resident?.genderId"
    [url]="resident?.imageFileId | fileIdToUrl | async"
  />
  @if (medication) {
    <ion-label>
      <h2>{{ resident | personName }}</h2>
      <p [innerHTML]="medication | medName"></p>
    </ion-label>
  }
</ion-item>

<ion-content class="form-content">
  <form [formGroup]="form" novalidate>
    <ion-item lines="none">
      <ion-checkbox
        labelPlacement="start"
        justify="space-between"
        mode="ios"
        formControlName="isOTC"
        [color]="'accent' | alcTheme"
      >
        Over The Counter
      </ion-checkbox>
    </ion-item>

    <div class="grid grid-cols-6 gap-x-6 gap-y-4 p-4">
      @if (!form?.controls?.isOTC?.value) {
        <mat-form-field class="form-input-stretch col-span-6">
          <mat-label>Pharmacy</mat-label>
          <mat-select formControlName="pharmacyId">
            @for (pharmacy of pharmacies(); track pharmacy.id) {
              <mat-option [value]="pharmacy?.id">
                {{ pharmacy?.name }}
              </mat-option>
            } @empty {
              <mat-option> No pharmacies found </mat-option>
            }

            @if ('facility.contacts.create' | hasPermission) {
              <mat-option (click)="createPharmacy()">
                <div class="flex justify-center">
                  <ion-button
                    fill="clear"
                    shape="round"
                    color="residents-primary"
                  >
                    <ion-icon slot="start" name="add" />
                    New Pharmacy
                  </ion-button>
                </div>
              </mat-option>
            }
          </mat-select>
          <mat-error> required </mat-error>
        </mat-form-field>
      }

      <mat-form-field class="form-input-stretch col-span-6">
        <mat-label>Prescriber</mat-label>

        <mat-select formControlName="medicalProfessionalId">
          @for (
            prescriber of prescribers();
            track prescriber.medicalProfessionalId
          ) {
            <mat-option [value]="prescriber?.medicalProfessionalId">
              {{ prescriber | personName }}
            </mat-option>
          } @empty {
            <mat-option> No prescribers found </mat-option>
          }

          @if ('facility.contacts.create' | hasPermission) {
            <mat-option (click)="createMedicalProfessional()">
              <div class="flex justify-center">
                <ion-button
                  fill="clear"
                  shape="round"
                  color="residents-primary"
                >
                  <ion-icon slot="start" name="add" />
                  New Physician
                </ion-button>
              </div>
            </mat-option>
          }
        </mat-select>
        <mat-error> required </mat-error>
      </mat-form-field>
      @if (!form?.controls?.isOTC?.value) {
        <mat-form-field class="form-input-stretch col-span-3">
          <mat-label>Rx Number</mat-label>
          <input matInput formControlName="rxNumber" />
          <mat-error> required </mat-error>
        </mat-form-field>
      }

      <mat-form-field class="form-input-stretch col-span-3">
        <mat-label>Refills</mat-label>
        <input matInput type="number" formControlName="refills" />
        <mat-error [alcError]="form.controls.refills" />
      </mat-form-field>

      @if (
        inventoryCountSetting()?.value &&
        medication.isNarcotic &&
        form.get('amountTypeId').value !== 'qty'
      ) {
        <alc-callout class="col-span-6 mb-4" type="warning">
          Narcotic counts can only be done on inventory with an amount type of
          Quantity.
        </alc-callout>
      }

      <mat-form-field class="form-input-stretch col-span-3">
        <mat-label>Amount Type</mat-label>

        <mat-select formControlName="amountTypeId">
          @for (amountType of lookups.amountTypes; track amountType.id) {
            <mat-option [value]="amountType?.id">
              {{ amountType?.name }}
            </mat-option>
          }
        </mat-select>
        <mat-error> required </mat-error>
      </mat-form-field>

      <mat-form-field class="form-input-stretch col-span-3">
        <mat-label>Measurement</mat-label>

        <mat-select formControlName="amountMeasurementId">
          @for (measurement of lookups.measurements; track measurement.id) {
            <mat-option [value]="measurement?.id">
              {{ measurement?.abbreviation }}
            </mat-option>
          }
        </mat-select>
        <mat-error> required </mat-error>
      </mat-form-field>

      <mat-form-field class="form-input-stretch col-span-6 md:col-span-3">
        <mat-label>
          @if (form?.get('amountTypeId').value === 'qty') {
            Quantity
          } @else {
            Total Amount
          }
        </mat-label>

        <input matInput formControlName="amount" />
        @if (form?.get('amountTypeId').value === 'total') {
          <span matTextSuffix>
            {{
              measurement?.id === 'unit'
                ? medicationForm?.name
                : measurement?.abbreviation
            }}
          </span>
        } @else {
          <span matTextSuffix>
            <!-- We use a literal "2" as a simple way to pluralize the medication form -->
            {{
              2
                | pluralize
                  : (medication?.formId | lookup: 'medicationForms')
                  : false
            }}
          </span>
        }

        <mat-hint> in the container </mat-hint>
        <mat-error> required </mat-error>
      </mat-form-field>

      <mat-form-field class="form-input-stretch col-span-6 md:col-span-3">
        @if (medication?.measurementId !== 'unit') {
          <mat-label>Unit Strength</mat-label>
        } @else {
          <mat-label>Base Unit</mat-label>
        }
        <input
          matInput
          type="text"
          formControlName="strength"
          [placeholder]="'e.g. ' + (measurement?.id | dosePlaceholder)"
        />
        <span matTextSuffix>
          {{
            measurement?.id === 'unit'
              ? medicationForm?.name
              : measurement?.abbreviation
          }}
        </span>
        @if (
          medication?.measurementId !== 'unit' &&
          (medication?.formId | lookup: 'medicationForms.amountTypeId') ===
            'qty'
        ) {
          <mat-hint>
            in a single
            {{ medication?.formId | lookup: 'medicationForms' | lowercase }}
          </mat-hint>
        }
        <mat-error> required </mat-error>
      </mat-form-field>

      <alc-date-input
        class="col-span-3"
        label="Fill Date"
        formControlName="fillDate"
        [max]="now"
        (dateChange)="changeExpirationDate()"
      />
      <alc-date-input
        class="col-span-3"
        label="Expiration Date"
        formControlName="expireDate"
        [min]="form?.get('fillDate').value"
      />
      <alc-date-input
        class="col-span-3"
        label="Start Date"
        formControlName="startDate"
      />
      <alc-date-input
        class="col-span-3"
        label="End Date"
        formControlName="endDate"
        [min]="form?.get('startDate').value"
      />
    </div>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        fill="solid"
        shape="round"
        color="residents-primary"
        (click)="submit()"
        [disabled]="form?.status === 'INVALID'"
        >{{ medicationInventoryItem ? 'Update' : 'Create' }}</ion-button
      >
      <ion-button
        fill="outline"
        shape="round"
        color="residents-primary"
        (click)="close()"
        >Cancel</ion-button
      >
    </div>
  </ion-toolbar>
</ion-footer>

import { FeatureFlag } from './constants/feature-flags';
import {
  NintendoVillageFacilityId,
  NintendoVillageOrgId
} from './constants/ids';
import { StateId } from './lookups/states.lookup';

export function applyDefaultsToFacilityFeatureFlags(
  facilityFeatureFlags: Record<string, boolean>,
  facility: { id?: string; state: StateId; orgId: string }
): Record<FeatureFlag, boolean> {
  if (!facility) {
    return {
      'documents-facility': false,
      'documents-incomplete': false,
      'documents-forms': false,
      'incident-reports': false,
      experiments: false,
      'move-resident': false,
      'medication-destruction': false,
      'narcotics-count': false,
      'medication-inventory-refresh': false,
      'group-by-care-task-list': false,
      'pharmacy-integration-medication-review': false,
      'resident-reports': false,
      'ai-summarize-notes': false
    };
  }

  const flags: Record<FeatureFlag, boolean> = {
    'documents-facility': false,
    'documents-incomplete': false,
    'documents-forms': facility?.id === NintendoVillageFacilityId,
    'incident-reports': facility?.state === 'US-CA',
    experiments: facility?.orgId === NintendoVillageOrgId,
    'move-resident': facility?.orgId === NintendoVillageOrgId,
    'medication-destruction': facility?.orgId === NintendoVillageOrgId,
    'narcotics-count': facility?.orgId === NintendoVillageOrgId,
    'medication-inventory-refresh': facility?.orgId === NintendoVillageOrgId,
    'group-by-care-task-list': facility?.id === NintendoVillageFacilityId,
    'pharmacy-integration-medication-review':
      facility?.id === NintendoVillageFacilityId,
    'resident-reports': facility?.orgId === NintendoVillageOrgId,
    'ai-summarize-notes': facility?.orgId === NintendoVillageOrgId
  };

  for (const [flag, enabled] of Object.entries(facilityFeatureFlags)) {
    if (flag in flags) {
      flags[flag as FeatureFlag] ||= enabled;
    }
  }

  return flags;
}

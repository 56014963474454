import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { MedicationsToOrderListPageActions } from '$/app/pages/medications/dashboard/medication-orders/medications-to-order-list/medications-to-order-list.actions';
import { NarcoticCountListPageActions } from '$/app/pages/medications/dashboard/narcotic-counts/narcotic-count-list/narcotic-count-list.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { MarSummaryPageActions } from '$/app/pages/medications/residents/mar-summary/mar-summary.actions';
import { MedicationDetailInfoPageActions } from '$/app/pages/medications/residents/medication-detail/medication-detail-info/medication-detail-info.actions';
import { MedicationDetailInventoryPageActions } from '$/app/pages/medications/residents/medication-detail/medication-detail-inventory/medication-detail-inventory.actions';
import { MedicationFormPageActions } from '$/app/pages/medications/residents/medication-form/medication-form.actions';
import { MedicationListPageActions } from '$/app/pages/medications/residents/medication-list/medication-list.actions';
import { EffectHelpersService, MedicationsApiService } from '$/app/services';
import { OmitMedicationFormPageActions } from '$/app/shared/pages/forms/omit-medication-form/omit-medication.modal.actions';
import { ofRoute } from '$/app/store/lib/ofRoute';
import { ResidentsApiActions } from '$/app/store/residents/actions';
import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import { RouterSelectors } from '$/app/store/router';
import { getAllPages, reducePaginatedResponses } from '$/app/utils';
import { ApiData } from '$/app/utils/api-data';
import { Paginated } from '$/models';
import { IMedication, IMedicationGraph } from '$shared/medications';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, merge, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { MedicationsApiActions, MedicationsGeneralActions } from './actions';

@Injectable()
export class MedicationsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly medicationsService = inject(MedicationsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly router = inject(Router);

  loadMedications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationListPageActions.loadResidentMedications,
        OmitMedicationFormPageActions.loadResidentMedications,
        MarSummaryPageActions.loadMarSummary,
        MedicationDestructionItemFormActions.loadMedications
      ),
      switchMap((action) => {
        return this.medicationsService.getAll(action.params).pipe(
          mergeMap((medications: Paginated<IMedication>) => {
            const responseData = new ApiData('medications', medications);
            responseData.setPrimaryAction(
              MedicationsApiActions.loadMedicationsSuccess
            );
            return responseData.getActions();
          }),
          catchError((err) =>
            of(MedicationsApiActions.loadMedicationsFail({ error: err }))
          )
        );
      })
    );
  });

  getMedications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NarcoticCountListPageActions.getMedications),
      this.effectHelpers.apiRequest({
        description: 'Get ',
        onRequest: (action) => this.medicationsService.getAll(action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medications',
            response,
            MedicationsApiActions.getMedicationsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => MedicationsApiActions.getMedicationsFail({ error })
      })
    );
  });

  fetchMedicationDetailPageInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationDetailInfoPageActions.fetchMedicationDetailPageInfo,
        MedicationDetailInventoryPageActions.fetchMedicationDetailPageInfo
      ),
      withLatestFrom(
        this.store.select(ResidentsSelectors.selectResident),
        this.store.select(RouterSelectors.selectParams)
      ),
      switchMap(([action, resident, routeParams]) => {
        const callMedicationService = () => {
          return this.medicationsService.getAll(action.params).pipe(
            map((medication: Paginated<IMedicationGraph>) =>
              MedicationsApiActions.fetchMedicationDetailPageInfoSuccess({
                medication: medication.data[0]
              })
            ),
            catchError((error) =>
              of(MedicationsApiActions.fetchMedicationFail({ error }))
            )
          );
        };

        if (resident) {
          return callMedicationService();
        }

        return merge(
          of(
            MedicationDetailInfoPageActions.fetchResident(
              routeParams.residentId
            )
          ),
          this.actions$.pipe(
            ofType(
              ResidentsApiActions.fetchResidentSuccess,
              ResidentsApiActions.fetchResidentFail
            ),
            first(),
            switchMap((residentAction) => {
              if (
                residentAction.type ===
                ResidentsApiActions.fetchResidentFail.type
              ) {
                return of(
                  MedicationsApiActions.fetchMedicationFail({
                    error: new Error('Pre-fetch resident failed')
                  })
                );
              }
              return callMedicationService();
            })
          )
        );
      })
    );
  });

  // Effect for handling successful medication eager queries
  fetchMedicationDetailPageInfoSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationsApiActions.fetchMedicationDetailPageInfoSuccess),
      mergeMap((action) => {
        const medicationResponse = new ApiData(
          'medications',
          action.medication
        );
        medicationResponse.setPrimaryAction(
          MedicationsGeneralActions.addMedications,
          { singleRecord: false }
        );

        return medicationResponse.getActions();
      })
    );
  });

  getDashboardOrdersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationsToOrderListPageActions.getMedicationsToOrderListPageInfo
      ),
      switchMap((action) => {
        return this.medicationsService.getAll(action.params).pipe(
          getAllPages(this.medicationsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((medications) => {
            const responseData = new ApiData('medications', medications);

            responseData.setPrimaryAction(
              MedicationsApiActions.getMedicationsSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) =>
            of(MedicationsApiActions.getMedicationsFail({ error }))
          )
        );
      })
    );
  });

  createMedication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationFormPageActions.createMedication),
      exhaustMap((action) =>
        from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
          switchMap(() =>
            this.medicationsService
              .create(action.medication, action.params)
              .pipe(
                tap(() =>
                  this.effectHelpers.handleParamsOnSuccess(action.params)
                ),
                tap(
                  this.effectHelpers.onModalFormSubmitSuccess(
                    'Medication created successfully!'
                  )
                ),
                map(() => MedicationsApiActions.createMedicationSuccess({})),
                catchError((error) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error,
                    message: 'Create Medication Failed'
                  });

                  return of(
                    MedicationsApiActions.createMedicationFail({
                      error
                    })
                  );
                })
              )
          )
        )
      )
    );
  });

  updateMedicationOld$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationFormPageActions.updateMedication,
        MedicationDetailInfoPageActions.discontinueMedication,
        MedicationDetailInfoPageActions.holdMedication,
        MedicationDetailInfoPageActions.activateMedication
      ),
      exhaustMap((action) =>
        from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
          switchMap(() =>
            this.medicationsService
              .patch(action.id, action.medication, action.params)
              .pipe(
                tap(() =>
                  this.effectHelpers.handleParamsOnSuccess(action.params)
                ),
                tap(
                  this.effectHelpers.onModalFormSubmitSuccess(
                    'IMedication updated successfully!'
                  )
                ),
                map((medication) =>
                  MedicationsApiActions.updateMedicationSuccess({ medication })
                ),
                catchError((error: any) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error
                  });

                  this.store.dispatch(
                    MedicationsApiActions.updateMedicationFail({
                      error
                    })
                  );
                  return EMPTY;
                })
              )
          )
        )
      )
    );
  });

  updateMedication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationListPageActions.bulkFlagToOrder,
        MedicationListPageActions.bulkUnflagToOrder,
        MedicationDetailInfoPageActions.toggleTrackInventory,
        MedicationDetailInfoPageActions.flagMedicationForOrdering,
        MedicationDetailInfoPageActions.unflagMedicationForOrdering,
        ResidentMedicationTasksPageActions.flagForOrdering,
        ResidentMedicationTasksPageActions.unflagForOrdering,
        MedicationDetailInfoPageActions.uploadScriptImage,
        MedicationDetailInfoPageActions.deleteScriptImage
      ),
      this.effectHelpers.apiRequest({
        description: 'Update ',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationsService.patch(
            action.id,
            action.medication,
            action.params
          );
        },
        onSuccess: (medication) =>
          MedicationsApiActions.updateMedicationSuccess({
            medication
          }),
        onError: (error) =>
          MedicationsApiActions.updateMedicationFail({ error })
      })
    );
  });

  deleteMedication$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationDetailInfoPageActions.deleteMedication),
        exhaustMap((action) =>
          from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
            switchMap(() =>
              this.medicationsService.delete(action.id, action.params).pipe(
                tap(() =>
                  this.effectHelpers.handleParamsOnSuccess(action.params)
                ),
                withLatestFrom(this.store.select(RouterSelectors.selectParams)),
                tap(([, params]) => {
                  this.router.navigate([
                    '/residents',
                    params.residentId,
                    'medications'
                  ]);
                }),
                catchError((error) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error,
                    message: 'Delete IMedication Failed'
                  });

                  this.store.dispatch(
                    MedicationsApiActions.deleteMedicationFail({ error })
                  );
                  return EMPTY;
                })
              )
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  deleteMedicationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationsApiActions.deleteMedicationSuccess),
        tap(
          this.effectHelpers.onModalFormSubmitSuccess(
            'IMedication deleted successfully!'
          )
        )
      );
    },
    { dispatch: false }
  );

  loadResidentMedicationsOnDemand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OmitMedicationFormPageActions.loadResidentMedications),
      switchMap((action) => {
        return this.medicationsService.getAll(action.params).pipe(
          map((medications: Paginated<IMedicationGraph>) =>
            MedicationsApiActions.getMedicationsSuccess({ medications })
          ),
          catchError((error) =>
            of(MedicationsApiActions.getMedicationsFail({ error }))
          )
        );
      })
    );
  });

  fetchMedicationForRoute$ = createEffect(() => {
    return this.actions$.pipe(
      ofRoute('/residents/:residentId/medications/:medicationBatchId/mars'),
      switchMap((action) => {
        const medicationBatchId =
          action.payload.routerState.params.medicationBatchId;
        return this.medicationsService
          .getAll({
            query: {
              batchId: medicationBatchId,
              active: true
            }
          })
          .pipe(
            mergeMap((medications) => {
              const responseData = new ApiData(
                'medications',
                medications,
                MedicationsApiActions.fetchMedicationSuccess,
                {
                  singleRecord: true,
                  payloadKey: 'medication'
                }
              );
              return responseData.getActions();
            }),
            catchError((error) =>
              of(MedicationsApiActions.fetchMedicationFail({ error }))
            )
          );
      })
    );
  });
}

import { OverlayService } from '$/app/services/ui';
import { AppInfo } from '$/app/services/utils';
import { environment as env, environment } from '$/environments/environment';
import { Logger } from '$shared/logger';
import { buildNumber } from '$shared/version';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import initFeathersAuthClient from '@feathersjs/authentication-client';
import feathers, { Application, Hook } from '@feathersjs/feathers';
import FeathersSocketIoClient from '@feathersjs/socketio-client';
import { Store } from '@ngrx/store';
import { merge, trim } from 'lodash';
import io from 'socket.io-client';
import { v4 as uuid } from 'uuid';
import { GeolocationSelectors } from '../store/geolocation/geolocation.selectors';
import { UserSelectors } from '../store/user/user.selectors';
import { SettingsValues } from '../utils/settings-values';
import { AuthenticationClientWithLogging } from './authentication-client-with-logging';

@Injectable({ providedIn: 'root' })
export class FeathersService implements OnDestroy {
  private readonly overlay = inject(OverlayService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);

  private socket: SocketIOClient.Socket;
  private geolocation = this.store.selectSignal(
    GeolocationSelectors.selectGeolocation
  );
  private user = this.store.selectSignal(UserSelectors.selectUser);

  private appUpdateMessageShowing = false;

  public client: Application;

  constructor() {
    const apiUrl =
      (AppInfo.deviceInfo.platform !== 'web' && env.mobileApiUrl) || env.apiUrl;

    Logger.debug('Connecting to API: ' + apiUrl);

    this.socket = io(apiUrl);

    // NOTE: If we ever want to use the REST interface in the app, see the "getService" function
    // in the cloud functions for an example of how to do that.
    this.client = feathers()
      .configure(FeathersSocketIoClient(this.socket, { timeout: 10000 }))
      .configure(
        initFeathersAuthClient({
          Authentication: AuthenticationClientWithLogging,
          storageKey: 'accessToken',
          storage: {
            getItem(key: string) {
              if (key !== 'accessToken') {
                return Logger.error(
                  `[Feathers Auth Storage: getItem] Unexpected key: ${key}`
                );
              }

              return SettingsValues.ACCESS_TOKEN;
            },
            setItem(key: string, value: any) {
              if (key !== 'accessToken') {
                return Logger.error(
                  `[Feathers Auth Storage: setItem] Unexpected key: ${key}`
                );
              }

              Logger.info(
                '[Feathers Auth Storage: removeItem] Saving access token'
              );

              SettingsValues.ACCESS_TOKEN = value;
            },
            removeItem(key: string) {
              if (key !== 'accessToken') {
                return Logger.error(
                  `[Feather Auth Storage: removeItem] Unexpected key: ${key}`
                );
              }

              Logger.warn(
                '[Feathers Auth Storage: removeItem] Removing access token'
              );

              SettingsValues.ACCESS_TOKEN = null;
            }
          }
        })
      )
      .hooks({
        before: {
          all: [this.attachExtras(), this.logRequest()]
        },
        after: {
          all: [this.logRequest()]
        },
        error: {
          all: [this.processErrors()]
        }
      });
  }

  ngOnDestroy() {
    this.socket.disconnect();
  }

  private attachExtras(): Hook {
    return (context) => {
      const user = this.user();
      const email = user?.email || 'anonymous';

      merge(context.params, {
        query: {
          // TODO(2024-03-22): Make this type-safe
          $extras: {
            requestId: uuid(),
            userAction: context.params?.userAction,
            userId: user?.id,
            email,
            geolocation: this.geolocation(),
            appInfo: AppInfo,
            // TODO(2024-03-22): Drop the fields below - update all uses to use appInfo directly
            deviceId: AppInfo.deviceId,
            deviceInfo: AppInfo.deviceInfo,
            platform: AppInfo.deviceInfo.platform,
            version: AppInfo.version,
            buildNumber
          }
        }
      });
    };
  }

  private logRequest(): Hook {
    return (context) => {
      if (environment.name === 'develop' || environment.name === 'test') {
        return;
      }

      const user = this.user();
      const email = user?.email || 'anonymous';

      const message = [email, context.method.toUpperCase(), `(${context.path})`]
        .map(trim)
        .filter(Boolean)
        .join(' ');

      if (context.type === 'before') {
        Logger.traceStart(message, context);
      } else {
        Logger.traceEnd(message, context);
      }
    };
  }

  private processErrors(): Hook {
    return (context) => {
      const error = context.error;

      context.error.data = context.error.data || {};
      error.data.requestId =
        error.data.requestId || context.params.query.$extras.requestId;
      error.data.extras = context.params.query.$extras;

      Logger.error(error.message, error.data);

      if (
        error.className === 'app-out-of-date-error' &&
        !this.appUpdateMessageShowing
      ) {
        this.appUpdateMessageShowing = true;

        this.overlay
          .showAlert(getAppOutOfDateMessage(), 'Update Available')
          .finally(() => {
            this.appUpdateMessageShowing = false;
          });
      }

      if (error.className === 'geofence-violation-error') {
        this.router.navigateByUrl('/geofence-violation');
      }
    };
  }
}

function getAppOutOfDateMessage(): string {
  if (AppInfo.deviceInfo.platform === 'web') {
    return [
      'A new version of the app is available.',
      'Please refresh the page to update to the new version.'
    ].join('\n\n');
  }

  return [
    'A new version of the app is available.',
    'Please close the app, and reopen it to reload the new version.'
  ].join('\n\n');
}
